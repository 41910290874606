/* FullCalendar custom styles for dark theme */
.fc {
    --fc-bg-color: #121212;
    --fc-page-bg-color: #121212;
    --fc-neutral-bg-color: #121212;
    --fc-border-color: rgba(255, 255, 255, 0.08);
    --fc-event-bg-color: rgba(255, 255, 255, 0.1);
    --fc-event-border-color: rgba(255, 255, 255, 0.2);
    --fc-event-text-color: #E1E1E6;
    --fc-today-bg-color: rgba(255, 255, 255, 0.05);
    --fc-list-event-hover-bg-color: rgba(255, 255, 255, 0.1);
  }
  
  .fc .fc-toolbar.fc-header-toolbar {
    color: #E1E1E6;
  }
  
  .fc .fc-toolbar-title {
    color: #E1E1E6;
  }
  
  .fc .fc-button {
    background-color: rgba(255, 255, 255, 0.1);
    color: #E1E1E6;
    border: none;
  }
  
  .fc .fc-button:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  
  .fc .fc-daygrid-day-number {
    color: #E1E1E6;
  }
  
  .fc .fc-day-today {
    background-color: rgba(255, 255, 255, 0.05);
  }
  
  .custom-day-cell {
    background-color: #121212;
    color: #E1E1E6;
    border: 1px solid rgba(255, 255, 255, 0.08);
  }
  